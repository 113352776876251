var exports = {};

exports = input => {
  const LF = typeof input === "string" ? "\n" : "\n".charCodeAt();
  const CR = typeof input === "string" ? "\r" : "\r".charCodeAt();

  if (input[input.length - 1] === LF) {
    input = input.slice(0, input.length - 1);
  }

  if (input[input.length - 1] === CR) {
    input = input.slice(0, input.length - 1);
  }

  return input;
};

export default exports;